<template>
  <div class="wrapper">
    <menu-ver type="profesor" :content="courses" v-on:theme="selectTheme" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          style="float: none"
          @click="$router.go(-1)"
        >
          <i class="fas fa-undo"></i> {{ $t("actions.return") }}
        </button>
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="questions && questions.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.key") }}</th>
            <th scope="col">{{ $t("maintenance.questions.question") }}</th>
            <th scope="col">{{ $t("maintenance.questions.canswer") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in questions" :key="item.prex_id">
            <td>{{ item.prex_id }}</td>
            <td>{{ item.prex_texto }}</td>
            <td>{{ item.prex_txt_correcta }}</td>
            <td style="min-width: 80pt;">
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :data-title="$t(`menu.borrar`)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.questions.addTitle", { name: `${questionId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.question`) + " *"
              }}</label>
              <textarea
                v-model.trim="prex_texto"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                maxlength="500"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 1"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op1"
                :placeholder="$t(`maintenance.questions.answer`) + ' 1'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 2"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op2"
                :placeholder="$t(`maintenance.questions.answer`) + ' 2'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 3"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op3"
                :placeholder="$t(`maintenance.questions.answer`) + ' 3'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 4"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op4"
                :placeholder="$t(`maintenance.questions.answer`) + ' 4'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 5"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op5"
                :placeholder="$t(`maintenance.questions.answer`) + ' 5'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 6"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op6"
                :placeholder="$t(`maintenance.questions.answer`) + ' 6'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.canswer`) + " *"
              }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="prex_correcta"
              >
                <option value="1">
                  {{ $t("maintenance.questions.answer") + " 1" }}
                </option>
                <option value="2">
                  {{ $t("maintenance.questions.answer") + " 2" }}
                </option>
                <option value="3">
                  {{ $t("maintenance.questions.answer") + " 3" }}
                </option>
                <option value="4">
                  {{ $t("maintenance.questions.answer") + " 4" }}
                </option>
                <option value="5">
                  {{ $t("maintenance.questions.answer") + " 5" }}
                </option>
                <option value="6">
                  {{ $t("maintenance.questions.answer") + " 6" }}
                </option>
              </select>
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addQuestion"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.questions.deleteTitle", { name: `${questionId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.questions.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteQuestion"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.questions.editTitle", { name: `${questionId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.question`) + " *"
              }}</label>
              <textarea
                v-model.trim="prex_texto"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                maxlength="500"
              />
            </div>

            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 1"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op1"
                :placeholder="$t(`maintenance.questions.answer`) + ' 1'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 2"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op2"
                :placeholder="$t(`maintenance.questions.answer`) + ' 2'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 3"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op3"
                :placeholder="$t(`maintenance.questions.answer`) + ' 3'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 4"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op4"
                :placeholder="$t(`maintenance.questions.answer`) + ' 4'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 5"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op5"
                :placeholder="$t(`maintenance.questions.answer`) + ' 5'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.answer`) + " 6"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prex_op6"
                :placeholder="$t(`maintenance.questions.answer`) + ' 6'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.questions.canswer`) + " *"
              }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="prex_correcta"
              >
                <option value="1">
                  {{ $t("maintenance.questions.answer") + " 1" }}
                </option>
                <option value="2">
                  {{ $t("maintenance.questions.answer") + " 2" }}
                </option>
                <option value="3">
                  {{ $t("maintenance.questions.answer") + " 3" }}
                </option>
                <option value="4">
                  {{ $t("maintenance.questions.answer") + " 4" }}
                </option>
                <option value="5">
                  {{ $t("maintenance.questions.answer") + " 5" }}
                </option>
                <option value="6">
                  {{ $t("maintenance.questions.answer") + " 6" }}
                </option>
              </select>
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editQuestion"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      questions: [],
      question: {},
      prex_texto: "",
      prex_id: "",
      prex_op1: "",
      prex_op2: "",
      prex_op3: "",
      prex_op4: "",
      prex_op5: "",
      prex_op6: "",
      prex_correcta: "",
      questionId: "",
      result: "",
      action: 0,
      prex_exam_cd: this.$route.params.id,
      formError: false,
      loading: false,
      courses: []
    };
  },
  created() {
    this.getQuestions();
    this.changeBreadCrumb(19);
    this.bigDrop();
    this.getcourses();
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listado`
        );
        // console.log("miscursos", res);
        if (res.data) {
          this.courses = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getQuestions() {
      try {
      } catch (error) {
        console.error(error);
      }
      const lista = {
        prex_exam_cd: this.prex_exam_cd,
      };
      // console.log("lista",lista);
      const res = await axios.post(
        `${this.servidor}api/op_usu.asp?tp=profesor&ac=listado_preg`,
        lista
      );
      if (res.data) {
        this.questions = res.data.data;
      }
    },
    async addQuestion() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const question = {
            prex_texto: this.prex_texto.toUpperCase(),
            prex_exam_cd: this.prex_exam_cd,
            prex_correcta: this.prex_correcta,
          };
          if (this.prex_op1) {
            question.prex_op1 = this.prex_op1.toUpperCase();
          } else {
            question.prex_op1 = "";
          }
          if (this.prex_op2) {
            question.prex_op2 = this.prex_op2.toUpperCase();
          } else {
            question.prex_op2 = "";
          }
          if (this.prex_op3) {
            question.prex_op3 = this.prex_op3.toUpperCase();
          } else {
            question.prex_op3 = "";
          }
          if (this.prex_op4) {
            question.prex_op4 = this.prex_op4.toUpperCase();
          } else {
            question.prex_op4 = "";
          }
          if (this.prex_op5) {
            question.prex_op5 = this.prex_op5.toUpperCase();
          } else {
            question.prex_op5 = "";
          }
          if (this.prex_op6) {
            question.prex_op6 = this.prex_op6.toUpperCase();
          } else {
            question.prex_op6 = "";
          }
          
          // console.log("POST", question);
          const res = await axios.post(
            `${this.servidor}api/op_usu.asp?tp=profesor&ac=grabar_preg`,
            question
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editQuestion() {
      this.result = "0";
      if (this.validateForm()) {
        try {
          this.loading = true;

          const question = {
            prex_id: this.prex_id,
            prex_texto: this.prex_texto.toUpperCase(),
            prex_exam_cd: this.prex_exam_cd,
            prex_correcta: this.prex_correcta,
          };
          if (this.prex_op1) {
            question.prex_op1 = this.prex_op1.toUpperCase();
          } else {
            question.prex_op1 = "";
          }
          if (this.prex_op2) {
            question.prex_op2 = this.prex_op2.toUpperCase();
          } else {
            question.prex_op2 = "";
          }
          if (this.prex_op3) {
            question.prex_op3 = this.prex_op3.toUpperCase();
          } else {
            question.prex_op3 = "";
          }
          if (this.prex_op4) {
            question.prex_op4 = this.prex_op4.toUpperCase();
          } else {
            question.prex_op4 = "";
          }
          if (this.prex_op5) {
            question.prex_op5 = this.prex_op5.toUpperCase();
          } else {
            question.prex_op5 = "";
          }
          if (this.prex_op6) {
            question.prex_op6 = this.prex_op6.toUpperCase();
          } else {
            question.prex_op6 = "";
          }
          // console.log("PUT", question);
          const res = await axios.post(
            `${this.servidor}api/op_usu.asp?tp=profesor&ac=actualizar_preg`,
            question
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          this.result = "1";
          console.error(error);
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteQuestion() {
      try {
        const borrado = {
          prex_id: this.prex_id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=borrar_preg`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.questionId = event[0];
    },
    validateForm() {
      if (
        this.prex_texto === "" ||
        this.prex_correcta === "" ||
        this.prex_correcta === null
      ) {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.prex_id = "";
      this.prex_texto = "";
      this.preg_exam_cd = "";
      this.prex_op1 = "";
      this.prex_op2 = "";
      this.prex_op3 = "";
      this.prex_op4 = "";
      this.prex_op5 = "";
      this.prex_op6 = "";
      this.prex_correcta = "";
      this.loading = false;
      this.formError = false;
      this.getQuestions();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.question = item;
        this.prex_id = item.prex_id;
        this.prex_texto = item.prex_texto;
        this.prex_op1 = item.prex_op1;
        this.prex_op2 = item.prex_op2;
        this.prex_op3 = item.prex_op3;
        this.prex_op4 = item.prex_op4;
        this.prex_op5 = item.prex_op5;
        this.prex_op6 = item.prex_op6;
        this.prex_correcta = item.prex_correcta;
        return;
      }
      if (item.prex_id) {
        this.prex_id = item.prex_id;
      }
    },
    selectTheme() {
      this.$router.push({
        path: `/profesor/inicio`,
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.btn {
  float: right;
}

input{
  margin-bottom:0px;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>